import request from '@/utils/request'

// export function getRoomverify(roomverify) {
//   return request({
//     url: 'pay/web/waterAndElectric/getRoomElecByVerify?roomverify=' + roomverify,
//     method: 'post'
//   })
// }

// export function getWaterverify(roomverify) {
//   return request({
//     url: 'pay/web/waterAndElectric/getRoomWaterByVerify?roomverify=' + roomverify,
//     method: 'post'
//   })
// }

export function createOrder(buyElectric, projectId) {
  return request({
    url: 'pay/web/payEleCostController/createOrder',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'buyElectric': buyElectric,
      'projectId': projectId
    }
  })
}

export function querySchoolList(projectId) {
  return request({
    url: 'pay/web/payEleCostController/querySchoolList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'projectId': projectId
    }
  })
}

export function queryBuildingList(projectId, areaid) {
  return request({
    url: 'pay/web/payEleCostController/queryBuildingList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'projectId': projectId,
      'kddzschoolid': areaid
    }
  })
}

export function queryRoomStringList(projectId, areaid, buildid, levelid) {
  return request({
    url: 'pay/web/payEleCostController/queryRoomList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'projectId': projectId,
      'kddzschoolid': areaid,
      'kddzbuildid': buildid,
      'kddzlevelid': levelid
    }
  })
}

export function queryUnitList(projectId, buildid) {
  return request({
    url: 'pay/web/payEleCostController/queryUnitList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'projectId': projectId,
      'kddzbuildid': buildid
    }
  })
}

export function querySydl(projectId, areaid, buildid, roomid) {
  return request({
    url: 'pay/web/payEleCostController/querySydl',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'projectId': projectId,
      'areaid': areaid,
      'buildid': buildid,
      'roomid': roomid
    }
  })
}
// 新开普http接口 查询剩余电量
export function querySydl4XkpHttp(projectId, roomid) {
  return request({
    url: 'pay/web/payEleCostController/querySydl',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'projectId': projectId,
      'kddzroomid': roomid
    }
  })
}
// 新开普http接口电费查询校区、楼栋、楼层、房间信息
export function getRoomInfo(projectId, levelid) {
  return request({
    url: 'pay/web/payEleCostController/queryRoomList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'projectId': projectId,
      'kddzlevelid': levelid
    }
  })
}
